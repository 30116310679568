export const removeCountryCity = (fullAddress) => {
  const amountStreet = fullAddress.split(',');
  amountStreet.splice(0, 2);
  const splicedBuilding = amountStreet.splice(1, 1);

  return {
    street: amountStreet.join(',').replace(' ', ''),
    building: splicedBuilding.join(',').replace(' ', ''),
  };
};
