export const YMAPS_SETTINGS = {
  apiKey: process.env.YANDEX_MAP_API_KEY,
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1',
};

export const INITIAL_COORDS = [43.237038, 76.909588];

export const INITIAL_BORDERS = [[
  [43.36768267687441, 77.08669607253732],
  [43.326485513639135, 77.08222954035487],
  [43.29356634345232, 77.08183011965711],
  [43.21892871438621, 77.0614056108651],
  [43.18639425445174, 77.0380595480159],
  [43.158013910550586, 77.06940714096838],
  [43.129271456989066, 76.91001532175656],
  [43.128725023399, 76.90632615086811],
  [43.124245765878825, 76.86501054315498],
  [43.1471512163065, 76.79608018335198],
  [43.16427206698311, 76.77244747579097],
  [43.17095089145011, 76.7633264395693],
  [43.17337219032853, 76.76216258152671],
  [43.17552365202914, 76.75595555974505],
  [43.17735268916668, 76.7548728773354],
  [43.17770436014499, 76.75486379172031],
  [43.17796484177694, 76.74913513705751],
  [43.17766715263217, 76.74556273377134],
  [43.17092319618407, 76.70134467129438],
  [43.16889971245487, 76.6871752082567],
  [43.16716604327803, 76.67956661725974],
  [43.1666271319299, 76.67401340192657],
  [43.167153347244124, 76.6742044661071],
  [43.17168757751762, 76.67667495034603],
  [43.18226560441239, 76.68308528728767],
  [43.18534257150757, 76.68472918978543],
  [43.186243091860455, 76.68462698421062],
  [43.1951178858661, 76.685976704593],
  [43.214325052046014, 76.68644234465492],
  [43.21550511607292, 76.6961813164478],
  [43.21891833805489, 76.70876070055984],
  [43.22363997119398, 76.7222583003652],
  [43.22531132912978, 76.7297319545526],
  [43.23373392172849, 76.72970552002789],
  [43.2459219365879, 76.72976669662711],
  [43.26813760117945, 76.7302172049728],
  [43.29894195470374, 76.77280523190555],
  [43.30429928185255, 76.78217412858453],
  [43.32946541880836, 76.79060830950704],
  [43.35591389002113, 76.79776415718665],
  [43.35638964603721, 76.79303367609891],
  [43.35777263987168, 76.79328204638345],
  [43.358057697784126, 76.79587233360509],
  [43.361275293080574, 76.79729649697724],
  [43.36596981824602, 76.81144430513514],
  [43.37170879992267, 76.83077393102431],
  [43.371630654752906, 76.84086170292314],
  [43.37057902128557, 76.84629259661276],
  [43.3697637188856, 76.84742328162807],
  [43.372060807639556, 76.8525838365538],
  [43.37179642053637, 76.85315218945034],
  [43.373550082570866, 76.85658301963537],
  [43.37221278702682, 76.85860586405693],
  [43.374012313515486, 76.86476151975778],
  [43.373394094463684, 76.87282875860674],
  [43.373171760165775, 76.87887912589257],
  [43.374416900047635, 76.88276026189273],
  [43.374437856284814, 76.88638281504156],
  [43.373778537004746, 76.89174747315751],
  [43.373882740539244, 76.89580022185069],
  [43.369913803018434, 76.90673526162743],
  [43.368530349789836, 76.91027176233804],
  [43.36793835819063, 76.91355243052347],
  [43.36455215494345, 76.93241449339992],
  [43.36000946117994, 76.940897994916],
  [43.3706849534981, 76.95008518141437],
  [43.37419911994385, 76.96002547147442],
],
];

export const GEOCODE_CONSTRAINTS = {
  rspn: 1,
  ll: '76.910350,43.239554',
  spn: '0.14,0.14',
};
