<template>
  <div class='wrapper'>
    <BlockedPage
      v-if="statusesFinishDelivered"
      :message="messageFromStatusDelivered"
    />

    <div class="logo-islands">
      <img src="@/assets/images/icons/horizontal-logo.svg" alt="">
    </div>

    <div class='map-container'>
      <YandexMap
        :coords="courierCurrentPoint"
        :zoom='16'
        :settings='settings'
        :controls="[]"
        @map-was-initialized='onMapInit'
        class='map'
      >
        <YmapMarker
          :icon='MarkerDestinationIcon'
          :coords="pointCoords"
          marker-id='destinationCoords'
          marker-type='placemark'
        />
      </YandexMap>

      <div class="order-cards">
        <div class="order-info">
          <div class="order-info__header">
            <h3 class="order-info__title">
              {{ readableStatus }}
            </h3>
            <p class="order-info__text">
              {{ readableTime }}
            </p>
          </div>
          <div class="order-info__divider"></div>
          <div class="order-info__buttons">
            <a class="order-info__button-wrapper" :href="`tel:${courierPhone}`">
              <div class="order-info__button">
                <img src="@/assets/images/icons/tel.svg" alt="">
              </div>
              <span class="order-info__button-text">Позвонить курьеру</span>
            </a>
          </div>
        </div>

        <div class="download-app">
          <div class="download-app__img-wrapper">
            <img
              src="@/assets/images/icons/choco-logo.webp"
              class="download-app__img"
              alt=""
            >
          </div>
          <a class="flex flex-col" :href="appLink" target="_blank">
            <h4 class="download-app__title">Установите приложение Choco</h4>
            <p class="download-app__text">
              Доставка из лучших ресторанов, свежие продукты за 15 минут
              и новые впечатления каждый день
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Centrifuge } from 'centrifuge';
import axios from 'axios';
import { stringify } from 'qs';
import { yandexMap, ymapMarker, loadYmap } from 'vue-yandex-maps';
import { mask } from 'vue-the-mask';

import WalkerGreen from '@/assets/images/courier/WalkerGreen.svg';
import WalkerGrey from '@/assets/images/courier/WalkerGrey.svg';
import AutoGrey from '@/assets/images/courier/AutoGrey.svg';
import AutoGreen from '@/assets/images/courier/AutoGreen.svg';
import { isIOS } from '../../shared/utils/functions/isIOS';

import { YMAPS_SETTINGS } from '../../shared/config/map';

import BlockedPage from '../../shared/components/BlockedPage/BlockedPage.vue';

import MarkerIconBPoint from '../../assets/images/icons/home.svg';

const stepRequest = 30;

const deliveryAuthCentrifuge = axios.create({
  baseURL: process.env.CENTRIFUGE_AUTH,
  paramsSerializer: stringify,
  transformRequest: [
    data => stringify(data),
  ],
});

function getCentToken() {
  const url = '/auth';

  const settings = {
    headers: {
      Authorization: `Basic ${btoa(unescape(encodeURIComponent(process.env.CENTRIFUGE_AUTH_CREDS)))}`,
    },
  };

  return requestCentToken(url, settings);
}

function updateCentToken() {
  const url = '/refresh';
  const settings = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('delivery-refresh-ctr')}`,
    },
  };

  return requestCentToken(url, settings);
}

function requestCentToken(url, settings) {
  const params = undefined;

  return deliveryAuthCentrifuge.post(url, params, settings).then(tokenSetter);
}

function tokenSetter({ data }) {
  localStorage.setItem('delivery-refresh-ctr', data.refresh_token);
  localStorage.setItem('delivery-access-ctr', data.access_token);

  return data.access_token;
}

function useDeliveryCentrifuge() {
  let centrifuge = null;
  let reconnect = false;

  const connector = (channelName, subscriber) => {
    if (centrifuge) {
      return;
    }

    const token = localStorage.getItem('delivery-access-ctr');
    reconnect = true;

    centrifuge = new Centrifuge(process.env.CENTRIFUGE_URL, {
      token,
    });

    centrifuge.on('disconnected', () => {
      if (reconnect) {
        updateCentToken()
          .then(token => {
            centrifuge.setToken(token);
            centrifuge.connect();
          })
          .catch(disconnector);
      }
    });

    const channel = centrifuge.newSubscription(channelName);

    channel.on('publication', (ctx) => {
      subscriber(ctx.data);
    });

    channel.subscribe();
    centrifuge.connect();
  };

  const disconnector = () => {
    if (centrifuge) {
      centrifuge.disconnect();
      centrifuge = null;
      reconnect = false;
    }
  };

  return { connector, disconnector };
}

const { connector, disconnector } = useDeliveryCentrifuge();

export default {
  name: 'OrdersNewView',
  directives: {
    mask,
  },
  components: {
    BlockedPage,
    YandexMap: yandexMap,
    YmapMarker: ymapMarker,
  },
  data() {
    return {
      intervals: {},
      statusesFinishDelivered: null,
      params: [],
      calculatedCourierCoords: {
        current: ['43.23712931228393', '76.91547813214072'],
        last: [],
      },
      orderDetails: {},
      mapState: null,
      MarkerDestinationIcon: {
        layout: 'default#image',
        imageHref: MarkerIconBPoint,
        imageSize: [60, 60],
        imageOffset: [-20, -30],
      },
      map: null,
      courierMarker: null,
      appLink: '',
      settings: YMAPS_SETTINGS,
    };
  },
  computed: {
    courierCurrentPoint() {
      return this.calculatedCourierCoords.current;
    },
    isCourierWalker() {
      return this.orderDetails?.courier?.transport_type === 'FOOT';
    },
    messageFromStatusDelivered() {
      const dictionary = {
        COMPLETED: 'Заказ доставлен',
        CANCELED: 'Заказ был отменён',
        default: 'Этот курьер уже уехал',
      };

      return dictionary[this.statusesFinishDelivered] ?? dictionary.default;
    },
    courierPhone() {
      return this.orderDetails?.courier?.phone || '';
    },
    readableStatus() {
      return this.orderDetails.state
        ? this.getReadableStatus()
        : '';
    },
    readableTime() {
      return this.orderDetails.estimated_arrived || this.orderDetails.should_arrived
        ? this.getReadableTime()
        : '';
    },
    markerCourier() {
      if (['ARRIVED'].includes(this.orderDetails.state)) {
        return this.isCourierWalker ? WalkerGreen : AutoGreen;
      }

      return this.isCourierWalker ? WalkerGrey : AutoGrey;
    },
    pointCoords() {
      return this.orderDetails?.address_to?.latitude
        ? [this.orderDetails.address_to.latitude, this.orderDetails.address_to.longitude]
        : [];
    },
  },
  watch: {
    orderDetails() {
      if (this.orderDetails?.courier?.id && this.orderDetails.service === 'DELIVERY') {
        getCentToken().then(this.initCentrifuge);
      }
    },
    markerCourier() {
      this.map.geoObjects.remove(this.markerCourier);
      this.createCourierPlacemark();
    },
  },
  created() {
    document.title = 'Отслеживание курьера';
    this.getQueryParams();

    loadYmap(YMAPS_SETTINGS)
      .then(() => {
        this.mapState = !!window.ymaps ? 'loaded' : 'failed';
      })
      .catch(() => {
        this.mapState = 'failed';
      });

    this.appLink = isIOS()
      ? 'https://apps.apple.com/kz/app/choco-оплата-кешбэк-рядом/id1346543292'
      : 'https://play.google.com/store/apps/details?id=kz.rahmet.app';
  },
  beforeDestroy() {
    disconnector();
  },
  methods: {
    initCentrifuge() {
      connector(`chocodelivery:courier_coordinates:$${this.orderDetails.courier.id}`, this.handlerSocketEvents);
    },
    handlerSocketEvents(data) {
      console.log('data >>>', data);
      this.courierSmoothlyMove([data.latitude, data.longitude]);
    },
    getReadableStatus() {
      const dict = {
        default: 'Курьер забрал ваш заказ',
        CREATED: 'Курьер забрал ваш заказ',
        ARRIVED: 'Курьер приехал к вам',
        CANCELED: 'Этот заказ отменён',
        COMPLETED: 'Этот заказ завершён',
      };

      return dict[this.orderDetails.state] ? dict[this.orderDetails.state] : dict.default;
    },
    getMapProjection() {
      return this.map.options.get('projection');
    },
    getReadableTime() {
      const { estimated_arrived, should_arrived } = this.orderDetails;

      const estimatedArrived = this.getFormatDate(estimated_arrived);
      const shouldArrived = this.getFormatDate(should_arrived);

      if (!estimatedArrived) {
        const predShouldArrived = new Date(should_arrived);
        predShouldArrived.setHours(predShouldArrived.getHours() - 3);

        return `Доставим заказ в ${this.getFormatDate(predShouldArrived)} - ${shouldArrived}`;
      }

      const diffTime = new Date(should_arrived) - new Date(estimated_arrived);

      return diffTime > 0
        ? `Доставим заказ в ${estimatedArrived} - ${shouldArrived}`
        : `Доставим заказ в ${shouldArrived} - ${estimatedArrived}`;
    },
    getFormatDate(dateString) {
      if (!dateString) {
        return null;
      }

      const date = new Date(dateString);
      const minutes = this.getFormatTime(date.getMinutes());
      const hours = this.getFormatTime(date.getHours());

      return `${hours}:${minutes}`;
    },
    getFormatTime(value) {
      return value.toString().padStart(2, '0');
    },
    createCourierPlacemark() {
      if (this.courierMarker) {
        this.map.geoObjects.remove(this.courierMarker);
      }

      // eslint-disable-next-line
      this.courierMarker = new ymaps.Placemark(
        this.courierCurrentPoint,
        {},
        {
          iconLayout: 'default#image',
          iconImageHref: this.markerCourier,
          iconImageSize: [32, 32],
          iconImageOffset: [-16, -16],
        },
      );

      this.map.geoObjects.add(this.courierMarker);
    },
    setInitialCoordsData({ address_from }) {
      this.calculatedCourierCoords.current = [address_from.latitude, address_from.longitude];
      this.calculatedCourierCoords.last = [address_from.latitude, address_from.longitude];

      if (this.courierMarker?.geometry) {
        this.setCourierCurrentPos();
      }
    },
    setCourierCurrentPos() {
      this.courierMarker.geometry.setCoordinates(this.calculatedCourierCoords.current);
    },
    courierSmoothlyMove(coords = []) {
      const lastCoordsZero = this.calculatedCourierCoords.last[0];

      if (lastCoordsZero && lastCoordsZero !== coords[0]) {
        this.calculatedCourierCoords.current = [...coords];

        const difCoords = [];
        const { last, current } = this.calculatedCourierCoords;

        const getDifCoords = (index) => {
          const dif = parseFloat(current[index]) - parseFloat(last[index]);
          const difStep = dif / (stepRequest - 1);

          return difStep.toFixed(8);
        };

        difCoords[0] = getDifCoords(0);
        difCoords[1] = getDifCoords(1);

        this.intervals.smootlyMove = setInterval(() => {
          const getCoords = (index) => {
            const floatCurrent = parseFloat(this.calculatedCourierCoords.current[index]);
            const floatDif = parseFloat(difCoords[index]);

            return parseFloat(floatCurrent + floatDif).toFixed(8);
          };

          this.calculatedCourierCoords.current = [getCoords(0), getCoords(1)];
          this.setCourierCurrentPos();
        }, 1000);

        setTimeout(() => {
          clearInterval(this.intervals.smootlyMove);
        }, (stepRequest - 1) * 1000);
      }

      this.calculatedCourierCoords.last = [...coords];
    },
    getOrderDetails() {
      const baseUrl = `${process.env.CHOCO_GATEWAY}${process.env.GATEWAY_API_PREFIX}`;
      const url = `${baseUrl}/v0/deliveries/get-point-data/${this.params.uuid}`;

      fetch(url)
        .then(res => res.json())
        .then(data => {
          this.orderDetails = data;
          this.calculatedCourierCoords.current = [data.courier.latitude, data.courier.longitude];
          this.statusesFinishDelivered = ['CANCELED', 'COMPLETED'].includes(data.state)
            ? data.state
            : '';
        })
        .catch(console.error);
    },
    getQueryParams() {
      const array2d = window.location.hash.split('?')[1].split('&').map((x) => x.split('='));

      this.params = Object.fromEntries(array2d.map(([k, v]) => [k, v]));
    },
    onMapInit(map) {
      try {
        this.mapState = 'initialized';
        this.map = map;

        this.getOrderDetails();

        setTimeout(() => {
          this.createCourierPlacemark();
          this.map.setCenter(this.courierCurrentPoint);
        }, 1000);
      } catch (error) {
        alert('alarm');
      }
    },
  },
};
</script>

<style scoped>
  .wrapper {
    @apply h-full flex flex-col justify-center items-center px-4 overflow-hidden;
    font-family: 'AlsSirius';
    letter-spacing: 0.07px;

    --text-secondary: #7B7A8C;
    --text-main: #000;
    --bg-main: #fff;
    --bg-secondary: #F1F2F7;
    --shadow-main: 0px 2px 8px 0px rgba(149, 157, 165, 0.20);
  }

  .logo-islands {
    @apply px-4 py-2 fixed top-4 h-9;
    background-color: var(--bg-main);
    width: 170px;
    border-radius: 100px;
    box-shadow: var(--shadow-main);
    z-index: 1;
  }

  .map {
    @apply w-screen h-screen;
  }

  .order-cards {
    @apply fixed bottom-4 left-4 flex flex-col gap-4 overflow-hidden;
  }

  .order-info {
    @apply rounded-2xl p-4 relative;
    background-color: var(--bg-main);
    box-shadow: var(--shadow-main);
  }

  .order-info__title {
    @apply font-bold text-lg;
    color: var(--text-main);
    letter-spacing: 0.24px;
  }

  .order-info__text {
    @apply text-base font-medium;
    color: var(--text-secondary);
  }

  .order-info__divider {
    @apply mt-4 h-0.5 absolute left-0;
    width: calc(100% + 32px);
    background-color: var(--bg-secondary);
  }

  .order-info__buttons {
    @apply flex justify-center items-center mt-8;
  }

  .order-info__button-wrapper {
    @apply flex flex-col items-center;
  }

  .order-info__button {
    @apply flex justify-center items-center p-3.5 rounded-xl;
    width: 52px;
    background-color: var(--bg-secondary);
    height: 52px;
  }

  .order-info__button-text {
    @apply text-center text-sm mt-1;
    color: var(--text-secondary);
    width: 74px;
    line-height: 16px;
  }

  .download-app {
    @apply flex gap-3 p-4 rounded-2xl;
    background-color: var(--bg-main);
    width: calc(100vw - 32px);
    box-shadow: var(--shadow-main);
  }

  .download-app__img-wrapper {
    @apply flex justify-center items-center;
  }

  .download-app__img {
    min-width: 40px;
    min-height: 36px;
  }

  .download-app__title {
    @apply text-left text-base font-medium  mb-1;
    color: var(--text-main);
  }

  .download-app__text {
    @apply text-left text-base;
    line-height: 1.1rem;
    color: var(--text-secondary);
  }
</style>
