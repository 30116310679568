import UAParser from 'ua-parser-js';

function isIOS() {
  const parser = new UAParser(navigator.userAgent);
  const parserResults = parser.getResult();

  return parserResults.os.name.toLowerCase().includes('ios');
}

export { isIOS };
