export const chocoCoords = ['43.23710391097393', '76.91550227238768'];

export const times = [
  '09:00 - 11:00',
  '11:00 - 13:00',
  '13:00 - 15:00',
  '15:00 - 17:00',
  '17:00 - 19:00',
  '19:00 - 21:00',
  '21:00 - 23:00',
  '23:00 - 01:00',
];
