<template>
  <div class="wrapper">
    <h2>Home page</h2>
    <hr>
    <div class="nav">
      <router-link to="/Calculator">Калькулятор</router-link>
      <router-link to="/Tracking">Треккинг</router-link>
      <router-link to="/Widget">Виджет</router-link>
    </div>
  </div>
</template>

<script>
export default {
};
</script>
