import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Calculator from '../views/calculator/Calculator.vue';
import Tracking from '../views/tracking/Tracking.vue';
import Widget from '../views/widget/Widget.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Calculator',
    name: 'Calculator',
    component: Calculator,
  },
  {
    path: '/Tracking',
    name: 'Tracking',
    component: Tracking,
  },
  {
    path: '/Widget',
    name: 'Widget',
    component: Widget,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
