<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import './assets/fonts/fonts.css';
import './assets/styles/main.css';

export default {};
</script>

<style src="./assets/tailwind.css">
</style>
