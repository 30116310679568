<template>
<div class="background">
  <div id="loader-1" class="loader"></div>
</div>
</template>

<script>
export default {
  name: '',
};
</script>

<style lang="postcss">
  .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #EEF2F7 ;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
    z-index: 2;
  }

  #loader-1:before, #loader-1:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 5px solid transparent;
    border-top-color: #EE3955;
  }

  #loader-1:before{
    z-index: 100;
    animation: spin 1s infinite;
  }

  #loader-1:after{
    border: 5px solid #E7EBEE;
  }

  @keyframes spin{
    0%{
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100%{
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

</style>
