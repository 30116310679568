<template>
  <button
  class="btn__widhet-0"
  >
    {{title}}
  </button>
</template>

<script>
export default {
  name: 'VButton',
  props: {
    title: {
      default: 'VButton',
    },
  },
};
</script>

<style>
  .btn__widhet-0 {
    height: 54px;
    background-color: #D92B17;
    border-radius: 8px;
    font-size: 18px;
    color: #fff;
  }
</style>
