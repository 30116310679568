<template>
  <div
    :class="activeInput ? 'active-input' : ''"
    class="z-1"
  >
    <functional-calendar
      v-model="calendarData"
      :configs="calendarConfigs"
      v-on:choseDay="dayClicked"
      class="test"
      @opened="activeInput = !activeInput"
      @closed="
        () => {
          callback();
          activeInput = false;
        }
      "
    />
  </div>
</template>

<script>
import { FunctionalCalendar } from 'vue-functional-calendar';

export default {
  components: {
    FunctionalCalendar,
  },
  props: {
    callback: {
      type: Function,
      default: () => { console.log('Нужно выбрать функцию'); },
    },
  },
  methods: {
    dayClicked() {
      this.$emit('changeDay');
    },
    active() {
      this.active = true;
    },
    passive() {
      this.active = false;
    },
  },
  data() {
    return {
      activeInput: false,
      calendarConfigs: {
        sundayStart: false,
        isDatePicker: true,
        placeholder: 'День',
        isModal: true,
        isAutoCloseable: true,
        transition: true,
        dayNames: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
        monthNames: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь',
        ],
        isLayoutExpandable: true,
        disabledDates: ['beforeToday'],
        value: '12',
        dateFormat: 'dd/mm/yyyy',
      },
      calendarData: {},
    };
  },
};
</script>

<style lang="css">

.active-input {
  outline: 2px solid #000;
  z-index: 2;
}

.vfc-main-container {
  box-sizing: border-box;
  font-family: "Gilroy";
  font-style: medium;
  font-weight: 400;
  font-display: swap;
  src: url("../../../assets/fonts/Gilroy/Gilroy-Medium.woff2") format("woff2"),
    url("../../../assets/fonts/Gilroy/Gilroy-Medium.woff") format("woff");
}

.vfc-single-input {
  border: none;
  height: 36px;
  border-left: 1px solid #dadfe5;
  border-radius: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  padding: 0 0 0 12px;
  display: flex;
  text-align: left;
  margin: auto 0;
  color: #000;
}

.vfc-single-input-error {
  border: none;
  height: 36px;
  border-left: 1px solid #C90B0B;
  border-radius: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  padding: 0 0 0 12px;
  display: flex;
  text-align: left;
  margin: auto 0;
  color: #000;
}

.vfc-marked {
  background-color: #fff;
}

.vfc-main-container {
  top: -300px;
}
</style>
