<template>
  <div class="wrapper">
    <div v-if="orderErrorCreated" class="modal absolute t-9">
      <h2 class="h1">
        Возникла ошибка, проверьте все поля и попробуйте ещё раз сохранить,
        если не сработало, перезагрузите страницу и попробуйте ещё раз,
        или обратитесь в службу поддержки
      </h2>
    </div>
    <div v-if="orderCreated" class="modal"><h2 class="h1">Быстрая доставка</h2>
      <img
        class="logo absolute t-20 r-20"
        src="../../assets/images/icons/horizontal-logo.svg"
        alt="raketa"
      />
      <div>
        <p class="description text-grey">Адрес доставки</p>
        <p class="description">{{ to.address.street }} {{ to.address.building }}</p>
      </div>
      <div>
        <p class="description text-grey">Время доставки</p>
        <p v-if="dateArr && plannedTime" class="description">
          {{ this.dateArr.join('/') }} - {{ this.$refs.time.value }}
        </p>
        <p v-else class="description">~ в течение 2 часов</p>
      </div>
      <!-- <div class="center">
        <p class="h1">Сохранено</p>
      </div> -->
    </div>
    <div v-if="!orderCreated" class="modal">
      <h2 class="h1">Быстрая доставка</h2>
      <img
        class="logo absolute t-20 r-20"
        src="../../assets/images/icons/horizontal-logo.svg"
        alt="raketa"
      />
      <div class="form__input">

        <div class="relative t-24">
          <label class="input__label" for="address">Куда доставить</label><p
            v-if="errors.address.status"
            class="input__error-msg">
            {{ this.errors.address.message }}
          </p>
          <input
            ref="inputAddress"
            class="input text-primary"
            :class="this.errors.address.status ? 'input__error' : ''"
            autocomplete="off"
            type="text"
            name="address"
            placeholder="Введите адрес"
            @keyup="changeInput"
            @input="getSuggest"
            :v-model="address"
          />
          <suggests
            :expanded="dropdownChooseAddress"
            :items="suggests"
            @changeValue="selectAddress"
          />
        </div>

        <YandexMap
          :coords="chocoCoords"
          :zoom="11"
          :settings="settings"
          :controls="[]"
          @map-was-initialized="onMapInit"
          @click="onMapClick"
          class="widget-map"
          v-model="address.street"
        >
          <YmapMarker
            v-if="coords"
            marker-id="toCoords"
            :controls="['geolocationControl']"
            :coords="toCoords"
          />
        </YandexMap>
      </div>

      <div class="form__inputs_3h t-24">
        <div class="form__input_3h">
          <label class="input__label" for="entrance">Подъезд</label>
          <input
            v-model="to.address.entrance"
            class="input no-border"
            type="text"
            name="entrance"
          />
        </div>

        <div class="form__input_3h">
          <label class="input__label" for="entrance">Этаж</label>
          <input v-model="to.address.floor" class="input no-border" type="text" name="floor" />
        </div>

        <div class="form__input_3h">
          <label class="input__label" for="entrance">Квартира/офис</label>
          <input
            v-model="to.address.appartment"
            class="input no-border"
            type="text"
            name="appartment"
          />
        </div>
      </div>

      <div
        @click="errors.datetime.status = false"
        class="pointer form__inputs_3h t-24"
      >
        <label class="input__label" for="chooseTime">Время доставки</label>
        <dropdown
          ref="plannedTime"
          class="input no-border form__input_3h"
          placeholder="В ближайшее время"
          :items="chooseTime"
          @changeValue="setVisibleDateTime"
        />

        <calendar
          ref="calendar"
          v-if="visibleDateTime"
          v-on:day-clicked="getDateTime"
          @changeDay="getDateTime"
          :calendarData="selectedDate"
          :callback="assignmentDate"
        />

        <dropdown
          v-if="visibleDateTime"
          ref="time"
          @changeValue="getDateTime"
          :class="
            errors.datetime.status ? 'input__error' : 'no-border form__input_3h'
          "
          class="input"
          placeholder="Время"
          :error="errors.datetime.status"
          :items="times"
        />
          <p
            v-if="errors.datetime.status"
            class="input__error-msg">
            {{ this.errors.datetime.message }}
          </p>
      </div>

      <div class="form__inputs_2h t-24">
        <div class="form__input_2h">
          <label
            class="input__label"
            for="phoneNumber"
          >
            Номер телефона
          </label>

          <input
            v-model="to.phone_number"
            class="input"
            :class="this.errors.phone_number.status ? 'input__error' : 'no-border'"
            type="tel"
            v-mask="'+7 (###) ###-##-##'"
            @input="errors.phone_number.status = false"
          />
        </div>

        <div class="form__input_2h">
          <label class="input__label" for="entrance">
            Имя
          </label>
          <p
            v-if="errors.phone_number.status"
            class="input__error-msg">
            {{ this.errors.phone_number.message }}
          </p>
          <input
            v-model="to.name"
            class="input no-border"
            type="text"
            name="floor"
          />
        </div>
      </div>

      <button class="btn btn__primary" @click="createPreorder">
        <p class="text-primary w-500">Сохранить</p>
      </button>
    </div>
  </div>
</template>

<script>
import { yandexMap, loadYmap, ymapMarker } from 'vue-yandex-maps';
import { mask } from 'vue-the-mask';
import MarkerIcon from '@/assets/images/icons/placemark.svg';
import { api } from '@/shared/services/api';
import debounce from 'debounce';
import { stringify } from 'qs';
import { YMAPS_SETTINGS } from '../../shared/config/map';
import Dropdown from '../../shared/components/Dropdown/Dropdown.vue';
import Suggests from '../../shared/components/Suggest/Suggests.vue';
import { times } from '../../shared/config/units';
import Calendar from '../../shared/components/Calendar/Calendar.vue';
import { removeCountryCity } from '../../shared/services/transorm/transformAddress';
import {
  validationPhoneForm,
  validationAddressForm,
} from '../../shared/services/validation/validation';

export default {
  components: {
    YandexMap: yandexMap,
    YmapMarker: ymapMarker,
    Calendar,
    Dropdown,
    Suggests,
  },
  data() {
    return {
      orderCreated: false,
      plannedTime: null,
      dateArr: null,
      errors: {
        datetime: {
          status: false,
          message: 'Укажите время доставки',
        },
        phone_number: {
          status: false,
          message: '',
        },
        address: {
          status: false,
          message: 'Ошибка ввода',
        },
      },
      UUID: {
        branch: '',
        order: '',
      },
      markerIcon: {
        layout: 'default#image',
        imageHref: MarkerIcon,
        imageSize: [42, 60],
        imageOffset: [-22, -60],
      },
      suggests: [],
      currentCity: '',
      suggestCity: '',
      address: {},
      chooseTime: [
        'В ближайшее время',
        'Указать время',
      ],
      dropdownTimesExpanded: false,
      dropdownChooseTimeExpanded: false,
      dropdownChooseAddress: false,
      city: null,
      times,
      chocoCoords: ['43.23710391097393', '76.91550227238768'],
      coords: [],
      toGeoObject: {},
      settings: YMAPS_SETTINGS,
      to: {
        address: {
          appartment: null,
          entrance: null,
          floor: null,
          street: null,
          building: null,
          longitude: null,
          latitude: null,
          geo_object: null,
        },
        phone_number: null,
        name: '',
      },
      planned_datetime: null,
      selectedDate: null,
      visibleDateTime: false,
      date: null,
      time: null,
      searchResults: [],
      orderErrorCreated: false,
    };
  },
  directives: {
    mask,
  },
  watch: {
    errors: {
      deep: true,
      handler() {
        if (this.errors.datetime.status) {
          document.querySelector('.vfc-single-input')?.classList.add('vfc-single-input-error');
        } else {
          document.querySelector('.vfc-single-input')?.classList.remove('vfc-single-input-error');
        }
      },
    },
  },
  computed: {
    hasToAddress() {
      return this.toGeoObject !== null || false;
    },
    toCoords() {
      if (this.toGeoObject) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.coords = [`${this.toGeoObject?.Point?.pos.split(' ')[1]}`, `${this.toGeoObject?.Point?.pos.split(' ')[0]}`];
        return [`${this.toGeoObject?.Point?.pos.split(' ')[1]}`, `${this.toGeoObject?.Point?.pos.split(' ')[0]}`];
      }
      return null;
    },
  },
  created() {
    this.getHashUUIDs();
    loadYmap(YMAPS_SETTINGS)
      .then(() => {
        const ymapsTimer = setInterval(() => {
          if (window?.ymaps) {
            clearInterval(ymapsTimer);
            this.ymapsLoaded = true;
            if (!this.preferSuggestCity) {
              this.suggestCity = this.currentCity;
            } else {
              this.suggestCity = this.preferSuggestCity;
            }
          }
        }, 100);
      })
      .catch(() => {
        console.log('проблема с загрузкой яндекс модуля');
      });
    this.setCurrentCity();
  },
  methods: {
    validationPhoneForm,
    validationAddressForm,
    createPreorder() {
      if (!this.validationAddressForm(this.to.address).status && !this.to.address.geo_object) {
        this.errors.address.status = true;
        this.errors.address.message = this.validationAddressForm(this.to.address)?.message;
      }
      // eslint-disable-next-line no-mixed-operators
      if (this.$refs.plannedTime.value === 'Указать время' && !this.plannedTime || this.$refs.plannedTime.value === 'Указать время' && !this.dateArr) {
        this.errors.datetime.status = true;
      }
      if (!this.validationPhoneForm(this.to.phone_number)) {
        this.errors.phone_number.status = true;
      }
      if (
        this.errors.address.status
        || this.errors.datetime.status
        || this.errors.phone_number.status
      ) {
        return;
      }
      this.to.phone_number = this.formatPhoneNumber(this.to.phone_number);
      const payload = {
        from: {
          address: this.UUID.branch,
        },
        to: this.to,
        payment_type: 2,
        raketa_order_uuid: this.UUID.order,
        city: this.city,
        planned_datetime: this.planned_datetime,
      };
      fetch(`${process.env.CHOCO_GATEWAY}/api-gate/v0/deliveries/create-raketa-order-uuid`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          if (res.ok) {
            this.orderCreated = true;
          } else {
            this.orderErrorCreated = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.orderErrorCreated = true;
        });
    },
    formatPhoneNumber(number) {
      return number
        .replaceAll(' ', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('-', '');
    },
    getDateTime() {
      this.plannedTime = this.$refs.time.value;
      this.dateArr = this.$refs.calendar.calendarData.selectedDate;
      if (this.plannedTime && this.dateArr) {
        // eslint-disable-next-line prefer-destructuring
        this.plannedTime = this.plannedTime.split(' ')[0];
        this.dateArr = this.dateArr.split('/');
        if (this.dateArr[1].length === 1) {
          this.dateArr[1] = `0${this.dateArr[1]}`;
        }
        const dateTime = `${this.dateArr[2]}-${this.dateArr[1]}-${this.dateArr[0]}T${this.plannedTime}`;
        this.planned_datetime = dateTime;
      }
    },
    getHashUUIDs() {
      const hashData = window.location.hash.split(/[=, &]/);
      // eslint-disable-next-line prefer-destructuring
      this.UUID.branch = hashData[1];
      // eslint-disable-next-line prefer-destructuring
      this.UUID.order = hashData[3];
    },
    onStreetChangeFrom: debounce(function on() {
      const payload = stringify({
        apikey: process.env.YANDEX_MAP_API_KEY,
        format: 'json',
        geocode: this.$refs.inputAddress.value,
      });

      api.ymaps.geocoder(payload)
        .then(({ response }) => {
          this.toGeoObject = response?.GeoObjectCollection?.featureMember[0]?.GeoObject;
          this.to.address.geo_object = this.toGeoObject;
          this.chocoCoords = this.toCoords;
          // eslint-disable-next-line prefer-destructuring
          this.to.address.latitude = this.to.address.geo_object.Point.pos.split(' ')[1];
          // eslint-disable-next-line prefer-destructuring
          this.to.address.longitude = this.to.address.geo_object.Point.pos.split(' ')[0];
        })
        .catch(console.error);
    }, 700),
    onMapClick(e) {
      this.errors.address.status = false;
      this.geocodeSinglePosition(window.ymaps, e.get('coords'))
        .then((res) => {
          this.toGeoObject = res.featureMember[0]?.GeoObject || null;
          this.to.address.geo_object = this.toGeoObject;
          this.address.street = this.toGeoObject.name;
          this.$refs.inputAddress.value = this.address.street;
          this.to.city = this.currentCity;
          this.to.address.street = this.toGeoObject.name;
          // eslint-disable-next-line max-len
          const address = this.to.address.geo_object.metaDataProperty.GeocoderMetaData.Address.formatted;
          this.to.address.street = removeCountryCity(address).street;
          this.to.address.building = removeCountryCity(address).building;
          // eslint-disable-next-line prefer-destructuring
          this.to.address.latitude = this.to.address.geo_object.Point.pos.split(' ')[1];
          // eslint-disable-next-line prefer-destructuring
          this.to.address.longitude = this.to.address.geo_object.Point.pos.split(' ')[0];
        });
    },
    geocodeSinglePosition(ymaps, coords) {
      return new Promise((resolve, reject) => ymaps.geocode(coords, { results: 1, json: true })
        .then((res) => resolve(res.GeoObjectCollection))
        .catch(reject));
    },
    changeInput() {
      this.address.street = this.$refs.inputAddress.value;
      this.dropdownChooseAddress = true;
    },
    selectAddress(value) {
      this.$refs.inputAddress.value = value;
      this.address.street = value;
      this.dropdownChooseAddress = false;
      this.setCurrentCity();
      this.onStreetChangeFrom();
      this.to.address.street = removeCountryCity(value).street;
      this.to.address.building = removeCountryCity(value).building;
    },
    setCurrentCity() {
      if (!this.currentCity) {
        fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${process.env.GEO_IP_API_KEY}`)
          .then((res) => {
            res.json()
              .then((data) => {
                if (!data?.city) {
                  this.currentCity = 'Алматы';
                } else {
                  this.currentCity = data.city.replace(' ', '');
                }
              });
          });
      } else if (this.currentCity !== this.address.street.split(',')[1]) {
        // eslint-disable-next-line prefer-destructuring
        this.currentCity = this.address.street.split(',')[1].replace(' ', '');
        this.suggestCity = this.currentCity;
      }
      this.to.city = this.currentCity;
    },
    setVisibleDateTime() {
      if (this.$refs.plannedTime.items[0] !== this.$refs.plannedTime.value) {
        this.visibleDateTime = true;
      } else {
        this.visibleDateTime = false;
        this.dateArr = null;
        this.plannedTime = null;
      }
    },
    onMapInit(map) {
      this.mapState = 'initialized';
      this.map = map;
    },
    assignmentDate() {
      this.selectedDate = this.$refs.calendar.calendarData.selectedDate;
    },
    getSuggest() {
      this.errors.address.status = false;
      const newSuggests = [];
      window.ymaps.suggest(`${this.suggestCity},${this.address.street}`)
        .then((items) => {
          if (items.length === 0) {
            this.suggestCity = '';
          }
          items.forEach((item) => {
            newSuggests.push(item.value);
          });
          this.suggests = newSuggests;
        });
    },
  },
};
</script>

<style scope>
  .wrapper {
    font-family: 'Gilroy';
  }
  .vfc-popover-container {
    height: 36px;
  }

  .vfc-single-input-error {
    border: none;
    height: 36px;
    border: 1px solid #C90B0B;
    border-radius: 6px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    padding: 0 0 0 12px;
    display: flex;
    text-align: left;
    margin: auto 0;
    color: #000;
  }
</style>
