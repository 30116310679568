/* eslint-disable import/no-unresolved */
<template>
  <div class='wrapper'>
    <Loader
      v-if="loading"
     />
    <div class="text-block">
      <h2 class="title">Сколько будет стоить доставка</h2>
      <h3 class="subtitle">Стоимость доставки зависит от расстояния:
        <span class="span-accent">100 ₸ за каждый километр.</span>
        Вы можете выбрать удобный способ оплаты: картой или по выставленному счету.
      </h3>
    </div>
    <div class="card-block">
      <div class="card-box">
        <div class="adress-form">
          <p class="title-form text-form">
            Рассчитайте стоимость доставки
          </p>
          <div class="input-block">
            <label class="input-label text-form" for="a">A</label>
            <input
            type="text"
            class="from form-input text-form"
            placeholder="Откуда"
            name="a"
            v-model="from.street"
            @focus="onFocus"
            @input="onStreetChangeFrom"
            >
            <div v-if="searchResultsFrom.length > 0">
          <p class="w-full px-4 text-xs text-gray-400">Результаты поиска</p>
          <button
            v-for="(item, i) in searchResultsFrom"
            :key="i"
            class="w-full px-4 py-2.5 flex flex-col
            focus:outline-none hover:bg-gray-100"
            @click="onSearchAddressClickFrom(i)"
          >
            <p class="text-sm">{{ item.GeoObject.name }}</p>
            <span class="text-xs text-gray-400">
              {{ item.GeoObject.description }}
            </span>
          </button>
        </div>
          </div>
          <div class="input-block">
            <label class="input-label text-form" for="a">B</label>
            <input
            type="text"
            class="to form-input text-form"
            placeholder="Куда"
            name="b"
            v-model="to.street"
            @focus="onFocus"
            @input="onStreetChangeTo"
            >
            <div v-if="searchResultsTo.length > 0">
          <p class="w-full px-4 text-xs text-gray-400">Результаты поиска</p>
          <button
            v-for="(item, i) in searchResultsTo"
            :key="i"
            class="w-full px-4 py-2.5 flex flex-col
            focus:outline-none hover:bg-gray-100"
            @click="onSearchAddressClickTo(i)"
          >
            <p class="text-sm">{{ item.GeoObject.name }}</p>
            <span class="text-xs text-gray-400">
              {{ item.GeoObject.description }}
            </span>
          </button>
        </div>
          </div>
          <v-button
            @click.native="fetchDeliveryTimeAndPrice()"
            title="Рассчитать"
           />
        </div>
        <div class='map-container'>
          <YandexMap
            :coords='coords'
            :zoom='11'
            :settings='settings'
            class='map'
            :controls="['geolocationControl']"
            @click='onMapClickOut'
            @map-was-initialized='onMapInit'
            @touchstart.native='hasAddress = false'
            v-model="to.street"
          >
            <YmapMarker
              v-if='hasFromAddress && !hasRoute'
              :icon='markerIcon'
              :coords='fromCoords'
              marker-id='fromCoords'
              marker-type='placemark'
            />
            <YmapMarker
              v-if='hasToAddress && !hasRoute'
              :icon='markerIcon'
              :coords='toCoords'
              marker-id='toCoords'
              marker-type='placemark'
            />
          </YandexMap>
        </div>
      </div>
      <div class="result">
        <p class="result-title">СТОИМОСТЬ ДОСТАВКИ</p>
        <p class="result-value" id='priceValue'>
          {{ this.expectedDeliveryPrice ? this.expectedDeliveryPrice + ' тг' : '-' }}
        </p>
        <p class="result-title">ВРЕМЯ ДОСТАВКИ</p>
        <p class="result-value" id='timeValue'>
          {{ this.expectedDeliveryTime ? this.expectedDeliveryTime + ' мин' : '-' }}
        </p>
        <div class="line"></div>
        <button class="btn-order">Оставить заявку</button>
      </div>
    </div>
    <p class="notes">*Доставка доступна только внутри окрашенной области</p>
    <p class="notes">*Оставить заявку сейчас нельзя</p>
  </div>
</template>

<script>
import Loader from '@/shared/components/Loader/Loader.vue';
import { mapGetters } from 'vuex';
import { yandexMap, ymapMarker, loadYmap } from 'vue-yandex-maps';
import debounce from 'debounce';
import { stringify } from 'qs';
import { mask } from 'vue-the-mask';
import MarkerIcon from '@/assets/images/icons/placemark.svg';
import { api } from '@/shared/services/api';
import VButton from '@/shared/components/VButton/VButton.vue';
import { filterAddress } from '../../shared/utils/map';
import {
  YMAPS_SETTINGS,
  INITIAL_COORDS,
  INITIAL_BORDERS,
  GEOCODE_CONSTRAINTS,
} from '../../shared/config/map';
import geocodeSinglePosition from '../../shared/utils/geocode-single-position';

export default {
  name: 'OrdersNewView',
  directives: {
    mask,
  },
  // inject: ['toast'],
  components: {
    Loader,
    VButton,
    YandexMap: yandexMap,
    YmapMarker: ymapMarker,
  },
  data() {
    return {
      value: {
        street: null,
      },
      loading: true,
      invalidInputs: false,
      searchResultsFrom: [],
      searchResultsTo: [],
      breadcrumbList: [
        { title: 'Заказы', route: 'orders' },
        { title: 'Новый заказ', route: 'orders-new' },
      ],
      userAddresses: {},
      dropdown: [
        { id: 1, title: 'В ближайшее время' },
        { id: 2, title: 'Указать время' },
      ],
      deliveryTime: 1,
      mapModal: false,
      mapState: null,
      coords: INITIAL_COORDS,
      polygon_coords: INITIAL_BORDERS,
      polygon_fill: {
        enabled: true,
        color: '#C4C4C4',
        opacity: 0.4,
      },
      polygon_stroke: {
        enabled: true,
        color: '#EE3955',
        width: '2px',
      },
      settings: YMAPS_SETTINGS,
      markerIcon: {
        layout: 'default#image',
        imageHref: MarkerIcon,
        imageSize: [42, 60],
        imageOffset: [-22, -60],
      },
      from: {
        id: null,
        street: '',
        entrance: null,
        floor: null,
        apartment: null,
        intercom: null,
        save: false,
        extra_info: '',
      },
      fromGeoObject: null,
      fromContacts: {
        name: null,
        phone: null,
      },
      to: {
        id: null,
        street: '',
        entrance: null,
        floor: null,
        apartment: null,
        intercom: null,
        extra_info: '',
      },
      toGeoObject: null,
      toContacts: {
        name: null,
        phone: null,
      },
      mapClickCounter: 0,
      map: null,
      hasRoute: false,
      product: '',
      time: '',
      date: null,
      expectedDeliveryPrice: null,
      expectedDeliveryTime: null,
    };
  },
  computed: {
    ...mapGetters('device', ['screenWidth']),
    hasToAddress() {
      return this.toGeoObject !== null || false;
    },
    hasFromAddress() {
      return this.fromGeoObject !== null || false;
    },
    fromCoords() {
      if (this.fromGeoObject) {
        const [lon, lat] = this.fromGeoObject.Point.pos.split(' ');
        return [Number(lat), Number(lon)];
      }
      return null;
    },
    toCoords() {
      if (this.toGeoObject) {
        const [lon, lat] = this.toGeoObject.Point.pos.split(' ');
        return [Number(lat), Number(lon)];
      }
      return null;
    },
    inputErrors() {
      return {
        fromAddress:
          this.from.street === null
          || !/.*[, ][0-9].*/.test(this.from.street)
          || this.fromGeoObject === null,
        fromNumber:
          this.fromContacts.phone === ''
          || (this.fromContacts.phone && this.fromContacts.phone.length !== 18),
        toAddress:
          this.to.street === null
          || !/.*[, ][0-9].*/.test(this.to.street)
          || this.toGeoObject === null,
        toNumber:
          this.toContacts.phone === ''
          || (this.toContacts.phone && this.toContacts.phone.length !== 18),
        date: this.deliveryTime === 2 && this.date === null,
        time:
          this.deliveryTime === 2
          && (this.time.length === 0 || this.time.length !== 5),
      };
    },
  },
  watch: {
    // fromGeoObject: function onChange() {
    //   this.fetchDeliveryTimeAndPrice();
    // },
    // toGeoObject: function onChange() {
    //   this.fetchDeliveryTimeAndPrice();
    // },
  },
  created() {
    loadYmap(YMAPS_SETTINGS)
      .then(() => {
        this.mapState = window.ymaps !== undefined ? 'loaded' : 'failed';
      })
      .catch(() => {
        (this.mapState = 'failed');
      });
  },
  methods: {
    fetchDeliveryTimeAndPrice() {
      if (this.fromGeoObject === null || this.toGeoObject === null) {
        return;
      }
      const [fromLon, fromLat] = this.fromGeoObject.Point.pos.split(' ');
      const [toLon, toLat] = this.toGeoObject.Point.pos.split(' ');
      const payload = `address_from=${fromLon}|${fromLat}&address_to=${toLon}|${toLat}`;
      // const payload = {};
      api.rocket.fetchDeliveryTimeAndPrice(payload)
        .then((res) => {
          this.expectedDeliveryPrice = res.price;
          this.expectedDeliveryTime = res.time;
        });
    },
    onSearchAddressClickFrom(index) {
      const addr = this.searchResultsFrom[index];
      this.value.street = addr.GeoObject.name;
      [this.value.longitude, this.value.latitude] = addr.GeoObject.Point.pos.split(' ');
      this.value.geo_object = addr.GeoObject;
      this.value.id = null;
      this.fromGeoObject = this.value.geo_object;
      this.$emit('onChange', this.value);
      this.configureRoute();
      this.searchResultsFrom = [];
    },
    onSearchAddressClickTo(index) {
      const addr = this.searchResultsTo[index];
      this.value.street = addr.GeoObject.name;
      [this.value.longitude, this.value.latitude] = addr.GeoObject.Point.pos.split(' ');
      this.value.geo_object = addr.GeoObject;
      this.value.id = null;
      this.toGeoObject = this.value.geo_object;
      this.$emit('onChange', this.value);
      this.configureRoute();
      this.searchResultsTo = [];
    },
    onFocus(key) {
      this.focused = key;
    },
    onMapInit(map) {
      this.mapState = 'initialized';
      this.map = map;
      this.drawPolygon();
    },
    drawPolygon() {
      const myPolygon = new window.ymaps.Polygon(
        this.polygon_coords,
        {
          hintContent: 'Регион доставки',
        },
        {
          // Курсор в режиме добавления новых вершин.
          editorDrawingCursor: 'crosshair',
          // Максимально допустимое количество вершин.
          editorMaxPoints: 5,
          // Цвет заливки.
          fillColor: '#C4C4C4',
          // Цвет обводки.
          strokeColor: '#999',
          // Ширина обводки.
          strokeWidth: 3,

          fillOpacity: 0.3,
        },
      );
      // Добавляем многоугольник на карту.
      this.map.geoObjects.add(myPolygon);
      myPolygon.events.add('click', (e) => {
        geocodeSinglePosition(window.ymaps, e.get('coords')).then(
          this.extractAndSetGeoObject,
        );
      });
      // временно, пока не найду последнюю загрузку яндекса
      // решает проблему кликов по неподгруженной карте
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    onMapClickOut() {
      console.log('не работаем мы тут');
    },
    onMapClick(e) {
      geocodeSinglePosition(window.ymaps, e.get('coords')).then(
        this.extractAndSetGeoObject,
      );
    },
    getLocation(pos) {
      const [Lon, Lat] = pos.split(' ');
      const coords = [];
      coords.push(parseFloat(Lon));
      coords.push(parseFloat(Lat));
      return coords;
    },
    // добавление меток на карту
    extractAndSetGeoObject(res) {
      const key = this.mapClickCounter % 2 === 0 ? 'from' : 'to';
      this.mapClickCounter += 1;
      if (key === 'from') {
        this.fromGeoObject = res.featureMember[0]?.GeoObject || null;
        this.isCorrectAddress = /.*[, ][0-9].*/.test(this.fromGeoObject.name);
        if (this.isCorrectAddress) {
          this.dropFromAddress();
          this.from.street = this.fromGeoObject.name;
        }
      } else {
        this.toGeoObject = res.featureMember[0]?.GeoObject || null;
        this.isCorrectAddress = /.*[, ][0-9].*/.test(this.toGeoObject.name);
        if (this.isCorrectAddress) {
          this.dropToAddress();
          this.to.street = this.toGeoObject.name;
        }
      }
      if (this.toGeoObject && this.fromGeoObject) {
        this.configureRoute();
      }
    },
    configureRoute() {
      if (this.fromGeoObject === null || this.toGeoObject === null) {
        return;
      }
      this.hasRoute = false;
      this.map.geoObjects.removeAll();
      this.drawPolygon();
      window.ymaps
        .route(
          [
            {
              type: 'wayPoint',
              point: this.fromGeoObject.Point.pos.split(' ').reverse(),
            },
            {
              type: 'wayPoint',
              point: this.toGeoObject.Point.pos.split(' ').reverse(),
            },
          ],
          {
            mapStateAutoApply: true,
          },
        )
        .then((route) => {
          const points = route.getWayPoints();
          const lastPoint = points.getLength() - 1;
          this.hasRoute = true;
          points.options.set('preset', 'islands#darkBlueStretchyIcon');
          points
            .get(0)
            .properties.set('iconContent', `А: ${this.fromGeoObject.name}`);
          points
            .get(lastPoint)
            .properties.set('iconContent', `B: ${this.toGeoObject.name}`);
          route.events.add('click', () => {});
          route.getPaths().options.set({
            balloonContentBodyLayout: window.ymaps.templateLayoutFactory.createClass(
              '$[properties.humanJamsTime]',
            ),
            strokeColor: '#ee3955',
            startIconFillColor: '#EDEDED',
            opacity: 0.7,
            boundedBy: this.polygon_coords,
            strictBounds: true,
          });
          this.map.geoObjects.add(route);
        });
    },
    onFromAddressChange(value) {
      this.dropFromAddress();
      this.from.apartment = value.apartment;
      this.from.entrance = value.entrance;
      this.from.floor = value.floor;
      this.from.id = value.id;
      this.from.intercom = value.intercom;
      this.from.street = value.street;
      this.fromGeoObject = value.geo_object;
      this.from.extra_info = value.extra_info;
      this.from.save = value.save;

      if (value.branch.phone_number) {
        this.fromContacts.phone = value.branch.phone_number;
      }

      if (value.branch.contact_name) {
        this.fromContacts.name = value.branch.contact_name;
      }

      this.configureRoute();
    },
    onToAddressChange(value) {
      this.dropToAddress();
      this.to.apartment = value.apartment;
      this.to.entrance = value.entrance;
      this.to.floor = value.floor;
      this.to.intercom = value.intercom;
      this.to.street = value.street;
      this.toGeoObject = value.geo_object;
      this.to.extra_info = value.extra_info;
      this.configureRoute();
    },
    dropFromAddress() {
      this.from = {
        id: null,
        street: null,
        entrance: null,
        floor: null,
        apartment: null,
        intercom: null,
        extra_info: '',
      };
    },
    dropToAddress() {
      this.to = {
        id: null,
        street: null,
        entrance: null,
        floor: null,
        apartment: null,
        intercom: null,
        extra_info: '',
      };
    },
    onDeliveryDateChange(value) {
      this.date = value;
    },
    onDeliveryTimeChange(index) {
      this.deliveryTime = index;
    },
    onPaymentMethodChange(key) {
      this.paymentMethod = key;
    },
    onCancelClick() {
      this.$router.push({ name: 'orders' });
    },
    onMapClicked() {
      this.mapModal = true;
    },
    onMapModalHide() {
      this.mapModal = false;
    },
    onStreetChangeFrom: debounce(function on(e) {
      const payload = stringify({
        apikey: process.env.YANDEX_MAP_API_KEY,
        format: 'json',
        rpsn: GEOCODE_CONSTRAINTS.rspn,
        ll: GEOCODE_CONSTRAINTS.ll,
        spn: GEOCODE_CONSTRAINTS.spn,
        geocode: e.target.value,
      });

      api.ymaps.geocoder(payload)
        .then(({ response }) => {
          const { featureMember } = response.GeoObjectCollection;
          filterAddress(featureMember)
            .then((res) => {
              this.searchResultsFrom = res;
            });
        })
        .catch(console.error);
    }, 700),
    onStreetChangeTo: debounce(function on(e) {
      const payload = stringify({
        apikey: process.env.YANDEX_MAP_API_KEY,
        format: 'json',
        rpsn: GEOCODE_CONSTRAINTS.rspn,
        ll: GEOCODE_CONSTRAINTS.ll,
        spn: GEOCODE_CONSTRAINTS.spn,
        geocode: e.target.value,
      });

      api.ymaps.geocoder(payload)
        .then(({ response }) => {
          const { featureMember } = response.GeoObjectCollection;
          filterAddress(featureMember)
            .then((res) => {
              this.searchResultsTo = res;
            });
        })
        .catch(console.error);
    }, 700),
  },
};
</script>

<style scoped>
  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card-block {
    display: flex;
    flex-direction: row;
  }
  .card-box {
    width: fit-content;
    display: flex;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 20px rgba(123, 154, 199, 0.2);
    margin: 0;
  }
  .adress-form {
    width: 500px;
    height: 318px;
    border-radius: 12px 0px 0px 12px;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .map {
    width: 370px;
    height: 318px;
    border-radius: 12px 12px 0px 0px;
  }
  .result {
    width: 271px;
    height: 318px;
    margin: 0 0 0 30px;
    background-color: #F6F4F0;
    border-radius: 12px;
  }
  .text-block {
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding: 50px 0;
  }
  .title {
    font-size: 40px;
    font-weight: bold;
    line-height: 50px;
    text-align: center;
  }
  .subtitle {
    font-size: 24px;
    line-height: 32px;
    max-width: 1056px;
    text-align: center;
  }
  .span-accent {
    color: #D92B17;
  }
  .text-form {
    font-size: 20px;
  }
  .title-form {
    font-weight: bold;
  }
  .btn-form {
    font-size: 18px;
    background-color: #D92B17;
    height: 54px;
    border-radius: 8px;
    color: #fff;
  }
  .form-input {
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 7px;
    height: 54px;
    padding: 0 0 0 62px;
    text-overflow: ellipsis;
  }
  .input-label {
    position: absolute;
    background-color: #D92B17;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    color: #FFF;
    text-align: center;
    margin: 12px 17px;
  }
  input:placeholder-shown {
    padding: 0 0 0 62px;
  }
  .result {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    padding: 32px 24px;
  }
  .result-title {
    color: #999;
  }
  .result-value {
    font-weight: bold;
  }
  .btn-order {
    font-size: 24px;
    color: #D92B17;
    font-weight: 500;
  }
  .line {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
    margin: 10px 0;
  }
  .notes {
    padding-top: 10px;
    color: #999;
  }
</style>
