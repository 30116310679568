<template>
  <div class="blocked-page">
    <div></div>
    <div class="blocked-page__text">
      <p class="blocked-page__text-content">{{ message }}</p>
    </div>
    <Logo class="logo-bottom" />
  </div>
</template>

<script>
import Logo from '@/shared/components/logo/Logo.vue';

export default {
  components: {
    Logo,
  },
  props: {
    message: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
  .blocked-page {
    position: absolute;
    background-color: #fff;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    color: #2F3747;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .logo-bottom {
    padding: 0 0 24px 0;
    margin: 0 auto;
  }
</style>
