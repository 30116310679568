import Vue from 'vue';
import YmapPlugin from 'vue-yandex-maps';
import VueMask from 'v-mask';
import App from './App.vue';
import router from './router/index';
import store from './store';
import settings from './shared/config/map';

Vue.use(YmapPlugin, settings);
Vue.use(VueMask);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
