/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import { stringify } from 'qs';

import { rocket } from './rocket';
import { ymaps } from './map';

import { transformError } from './transform-error';

const client = axios.create({
  baseURL: process.env.CHOCO_GATEWAY,
  paramsSerializer: stringify,
  transformRequest: [
    (data) => JSON.stringify(data),
  ],
});

const mapclient = axios.create({
  paramsSerializer: stringify,
  transformRequest: [
    (data) => stringify(data),
  ],
});

const deliveryClient = axios.create({
  baseURL: process.env.DELIVERY_API_URL,
  paramsSerializer: stringify,
  transformRequest: [
    (data) => JSON.stringify(data),
  ],
});

client.interceptors.response.use(
  (res) => {
    return res.data.error_code && res.data.error_code !== 0
      ? Promise.reject(transformError(res.data))
      : res;
  },
  (err) => Promise.reject(transformError(err)),
);

const api = {
  getContext() {
    return { ...client.context };
  },
  setDeviceId(deviceId) {
    client.context.deviceId = deviceId;
  },
  setSessionId(sessionId) {
    client.context.sessionId = sessionId;
  },
  rocket: rocket(client),
  ymaps: ymaps(mapclient),
};

export { deliveryClient, api };
