<template>
  <div
    class="z-10"
    :class="expanded ? 'visible' : 'none'"
  >
    <div class="overlay" @click="expanded = !expanded"></div>
    <ul class="dropdown__list t-36"
    >
      <li
        v-for="(item, index) in items"
        :key="index"
      >
        <p
          class="dropdown__item text-primary"
          @click="selectValue(item)"
        >
          {{ item }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: '',
    };
  },
  methods: {
    selectValue(item) {
      this.value = item;
      this.$emit('changeValue', this.value);
    },
  },
};
</script>
