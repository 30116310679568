<template>
  <div
    :class="expanded ? 'active-input' : ''"
    class="dropdown"
    @click="expanded = !expanded"
  >
    <div class="flex">
      <p class="text-primary">{{ value ? value : placeholder }}</p>
      <img class="absolute r-12" src="../../../assets/images/icons/down.svg" alt="v">
      <div v-if="expanded" class="overlay"></div>
    </div>
    <ul
      v-if="expanded"
      class="dropdown__list"
    >
      <li v-for="(item, index) in items" :key="index">
        <p
          class="dropdown__item text-primary"
          @click="chooseItem(index)"
        >{{ item }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      expanded: false,
      value: '',
    };
  },
  methods: {
    chooseItem(index) {
      this.value = this.items[index];
      this.$emit('changeValue', this.value);
    },
  },
};
</script>

<style>
</style>
