/* eslint-disable import/prefer-default-export */
export function rocket(client) {
  return {
    profile(xUser) {
      return client.get('/user/v2/', {
        'X-User': xUser,
      })
        .then((response) => response.data);
    },
    getRaketaOrderData(payload) {
      return client.get(`/${process.env.GATEWAY_API_PREFIX}v0/deliveries/get-order-data/${payload}`)
        .then((response) => response);
    },
    balance() {
      return client.get(`/${process.env.GATEWAY_API_PREFIX}/v0/business/company`)
        .then((response) => response.data);
    },
    fetchAddresses() {
      return client.get(`/${process.env.GATEWAY_API_PREFIX}/v0/common/addresses?is_customer=false`)
        .then((response) => response.data);
    },
    getCompany() {
      return client.get(`/${process.env.GATEWAY_API_PREFIX}/v0/business/company`)
        .then((response) => response.data);
    },
    createCompany(phone) {
      return client.post(`/${process.env.GATEWAY_API_PREFIX}/v0/business/company`, { phone_number: `+${phone}` })
        .then((response) => response.data);
    },
    warehouses() {
      return client.get(`/${process.env.GATEWAY_API_PREFIX}/v0/business/branches`)
        .then((response) => response.data);
    },
    deleteWarehouse(id) {
      return client.delete(`/${process.env.GATEWAY_API_PREFIX}/v0/business/branches/${id}`)
        .then((response) => response.data);
    },
    addAddress(payload) {
      return client.post(`/${process.env.GATEWAY_API_PREFIX}/v0/common/addresses`, payload)
        .then((response) => response.data);
    },
    editAddress(payload) {
      const { id } = payload;
      return client.put(`/${process.env.GATEWAY_API_PREFIX}/v0/common/addresses/${id}`, payload)
        .then((response) => response.data);
    },
    cancelOrder(id, payload) {
      return client.post(`/${process.env.GATEWAY_API_PREFIX}/v0/deliveries/cancel/${id}`, payload)
        .then((response) => response.data);
    },
    addWarehouse(payload) {
      return client.post(`/${process.env.GATEWAY_API_PREFIX}/v0/business/branches`, payload)
        .then((response) => response.data);
    },
    detailedWarehouse(id) {
      return client.get(`/${process.env.GATEWAY_API_PREFIX}/v0/business/branches/${id}`)
        .then((response) => response.data);
    },
    updateWarehouse(payload) {
      const { id } = payload;
      return client.put(`/${process.env.GATEWAY_API_PREFIX}/v0/business/branches/${id}`, payload)
        .then((response) => response.data);
    },
    createOrder(payload) {
      return client.post(`/${process.env.GATEWAY_API_PREFIX}/v0/deliveries/orders`, payload)
        .then((response) => response.data);
    },
    orderById(id) {
      return client.get(`/${process.env.GATEWAY_API_PREFIX}/v0/deliveries/orders/${id}`)
        .then((response) => response.data);
    },
    ordersList(payload) {
      let URL = `/${process.env.GATEWAY_API_PREFIX}/v0/deliveries/orders?`;
      URL += payload.page ? `page=${payload.page}` : '';
      URL += payload.status ? `&status=${payload.status}` : '';
      URL += payload.address ? `&address=${payload.address}` : '';
      URL += payload.start_date ? `&start_date=${payload.start_date}` : '';
      URL += payload.end_date ? `&end_date=${payload.end_date}` : '';
      URL += payload.search ? `&search=${payload.search}` : '';
      URL += `&was_returned=${payload.was_returned}`;

      return client.get(URL)
        .then((response) => response.data);
    },
    orderByRaketaOrderUUID(payload) {
      fetch(`${process.env.CHOCO_GATEWAY}/api-gate/v0/deliveries/create-raketa-order-uuid`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          const response = res;
          return response;
        })
        .catch((err) => {
          const error = err;
          return error;
        });
    },
    fetchDeliveryTimeAndPrice(payload) {
      return client.get(`/${process.env.GATEWAY_API_PREFIX}/v0/deliveries/orders/price_time?${payload}`)
        .then((response) => response.data);
    },
    fetchReports(payload) {
      let URL = `/${process.env.GATEWAY_API_PREFIX}/v0/deliveries/orders/reports?`;
      URL += payload.page ? `page=${payload.page}` : '';
      URL += payload.address ? `&address=${payload.address}` : '';
      URL += payload.start_date ? `&start_date=${payload.start_date}` : '';
      URL += payload.end_date ? `&end_date=${payload.end_date}` : '';

      return client.get(URL)
        .then((response) => response.data);
    },

    downLoadReport(payload) {
      let URL = `/${process.env.GATEWAY_API_PREFIX}/v0/reports/report`;
      URL += payload;

      return client.get(URL)
        .then((response) => response);
    },
  };
}
