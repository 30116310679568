<template>
  <div class="logo-block">
    <img src="./img/horizontal-logo.svg" alt="logo" class="logo-image">
  </div>
</template>

<script>
export default {

};
</script>

<style>
  .logo-block {
    display: flex;
    justify-items: center;
    align-items: center;
    width: 185px;
    height: auto;
  }

  .logo-text-image {
    height: fit-content;
  }
</style>
