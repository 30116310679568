export const validationPhoneForm = (value) => {
  if (value && value?.length === 18) {
    return true;
  }
  return false;
};

export const validationAddressForm = (value) => {
  if (value.street && value.building) {
    return {
      status: true,
      message: 'Всё правильно',
    };
  }
  if (!value) {
    return {
      status: false,
      message: 'Укажите адрес',
    };
  }
  return {
    status: false,
    message: 'Укажите адрес',
  };
};
